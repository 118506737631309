export default {
    links: [
        {
            icon: 'article',
            title: 'Production',
            description: 'notabene-1.0',
            to: 'articles-drafts',
            permissions: ['create content'],
            roles: [
                'Publishing'
            ]
        },
        {
            icon: 'cloud_upload',
            title: 'Articles',
            description: 'Published articles',
            to: 'articles',
            permissions: ['create content'],
            roles: [
                'Publishing'
            ]
        },
        {
            icon: 'comment',
            title: 'Comments',
            description: 'Manage comments',
            to: 'comments',
            permissions: ['manage comments'],
            roles: [
                'Comments moderator'
            ]
        },
        {
            icon: 'account_circle',
            title: 'Contributors',
            description: 'Manage companies and authors',
            to: 'contributors',
            permissions: ['read contributors'],
            roles: [
                'Editorial'
            ]
        },
        {
            icon: 'business',
            title: 'Clients',
            description: 'Coverage and office reports',
            to: 'clients',
            permissions: ['read agreements'],
            roles: [
                'crm', 'sales'
            ]
        },
        {
            icon: 'book',
            title: 'Article analytics',
            description: 'Find analytics for an article',
            to: 'analytics-articles',
            permissions: ['read agreements']
        },
        {
            icon: 'spellcheck',
            title: 'Intellitext estimates',
            description: 'Intellitext analytics',
            to: 'analytics-intellitext',
            permissions: ['read agreements']
        },
        {
            icon: 'file_download',
            title: 'Downloads',
            description: 'Download related analytics',
            to: 'analytics-downloads',
            permissions: ['read agreements'],
            roles: [
                'crm', 'sales'
            ]
        },
        // {
        //     icon: 'event_seat',
        //     title: 'Microsite Registrations',
        //     description: 'Registrations for microsites',
        //     to: 'registrations',
        //     permissions: ['read agreements'],
        //     roles: [
        //         'crm', 'sales'
        //     ]
        // },
        {
            icon: 'flip_to_front',
            title: 'Frontpage',
            description: 'What does today look like?',
            to: 'frontpage',
            permissions: ['read layouts'],
            roles: [
                'editorial'
            ]
        },
        {
            icon: 'show_chart',
            title: 'Surveys',
            description: 'Survey responses and graphs',
            to: 'surveys',
            permissions: ['read surveys'],
            roles: [
                'survey'
            ]
        },
        // {
        //     icon: 'show_chart',
        //     title: 'Surveys',
        //     description: 'Survey responses and graphs',
        //     to: 'surveys2',
        //     permissions: ['read surveys'],
        //     roles: [
        //         'survey'
        //     ]
        // },
        {
            icon: 'stars',
            title: 'Categories',
            description: 'Categories - overview and sponsors',
            to: 'categories',
            permissions: ['read modules'],
            roles: [
                'editorial'
            ]
        },
        {
            icon: 'trending_up',
            title: 'Daily report',
            description: 'Daily report',
            to: 'analytics-daily',
            permissions: ['analytics content'],
            roles: [
                'editorial'
            ]
        },
        {
            icon: 'view_module',
            title: 'Spotlight',
            description: 'Spotlight module',
            to: 'spotlight',
            permissions: ['read modules'],
            roles: [
                'Editorial'
            ]
        },
        {
            icon: 'view_module',
            title: 'Cartoons',
            description: 'Manage cartoons',
            to: 'cartoons',
            permissions: ['read modules'],
            roles: [
                'Editorial'
            ]
        },
        {
            icon: 'event',
            title: 'Events',
            description: 'Events modules, news and matchmaking',
            to: 'events',
            permissions: ['read modules', 'manage events'],
            roles: [
                'Editorial'
            ]
        },
        {
            icon: 'view_module',
            title: 'Modules',
            description: 'Create, read and update modules',
            to: 'modules',
            permissions: ['read modules', 'manage events'],
            roles: ['Editorial'],
            /*publication: 'africa'*/
        },
        {
            icon: 'email',
            title: 'Newsletters',
            description: 'Manage layout and articles for newsletters',
            to: 'layouts',
            permissions: ['read layouts', 'ordering layouts'],
            roles: [
                'Editorial'
            ]
        },
        {
            icon: 'dns',
            title: 'Domain Stats',
            description: 'Domain Stats',
            to: 'domainstats',
            permissions: ['read domainstats'],
            roles: [
                'Editorial'
            ]
        },
        {
            icon: 'account_balance',
            title: 'ClientTracker',
            description: 'Manage agreements',
            to: 'agreements',
            permissions: ['update agreements'],
            roles: [
                'crm', 'sales'
            ]
        },
        {
            icon: 'supervised_user_circle',
            title: 'User & ACL management',
            description: 'Manage users, roles and permissions',
            to: 'user-management',
            permissions: ['manage acl', 'manage consent'],
            roles: ['Administrator']
        },
        {
            icon: 'menu',
            title: 'Menu',
            description: 'Manage navigation drawer',
            to: 'menu',
            permissions: ['manage menu'],
            roles: ['Menu Administrator']
        },
        {
            icon: 'style',
            title: 'Office',
            description: 'Create and update office',
            to: 'office',
            permissions: ['create office'],
            roles: ['Administrator']
        },
        {
            icon: 'forward',
            title: 'ICT Insight',
            description: 'ICT Insight articles',
            to: 'ict-insight',
            permissions: ['read ictinsight'],
            roles: ['Administrator']
        },
        {
            icon: 'video_library',
            title: 'Video',
            description: 'Video content',
            to: 'videos',
            permissions: ['manage videos'],
            roles: ['Videographer']
        },
        {
            icon: 'dynamic_form',
            title: 'Forms',
            description: 'Registration forms',
            to: 'forms',
            permissions: ['read registrations'],
            roles: [
                'Form administrator'
            ]
        },
        {
            icon: 'people',
            title: 'Events portals',
            description: 'Create and update events portals.',
            to: 'events-portals',
            permissions: ['create events portals'],
            roles: [
                'Online designer'
            ]
        },
        {
            icon: 'people',
            title: 'Control Room',
            description: 'Control Room for live events.',
            to: 'roomControl',
            permissions: ['manage event platform'],
            roles: [
                'Event Platform Administrator'
            ]
        },
        {
            icon: 'people',
            title: 'Books',
            description: 'Books Management',
            to: 'books',
            permissions: ['create events portals'],
            roles: [
                'Administrator'
            ]
        },
        {
            icon: 'people',
            title: 'Tables',
            description: 'Tables Management',
            to: 'tables',
            permissions: ['read registrations'],
            roles: [
                'Form administrator'
            ]
        },
        // {
        //     icon: 'people',
        //     title: 'QR Code',
        //     description: 'QR Code',
        //     to: 'qrcode',
        //     permissions: ['read qrcode'],
        //     roles: [
        //         'Administrator'
        //     ]
        // },
        // {
        //     icon: 'people',
        //     title: 'Inspire',
        //     description: 'Inspire',
        //     to: 'inspire',
        //     permissions: ['read modules', 'manage events'],
        //     roles: [
        //         'Administrator'
        //     ]
        // },
        {
            icon: 'people',
            title: 'Search',
            description: 'Search',
            to: 'search',
            permissions: ['read qrcode'],
            roles: [
                'Administrator'
            ]
        },
        {
            icon: 'people',
            title: 'Subscribers',
            description: 'Subscriber management',
            to: 'subscriber',
            permissions: ['manage subscribers'],
            publication: 'brainstorm',
            roles: [
                'Circulation'
            ]
        },
        // {
        //     icon: 'people',
        //     title: 'CIO Table',
        //     description: 'table',
        //     to: 'cio-table',
        //     permissions: ['read qrcode'],
        //     roles: [
        //         'Administrator'
        //     ]
        // },
        {
            icon: 'people',
            title: 'Dilbert',
            description: 'Create and update dilbert portals.',
            to: 'dilbert',
            permissions: ['create events portals'],
            roles: [
                'Online designer'
            ]
        },
        {
            icon: 'calendar_month',
            title: 'Calendar',
            description: 'Create Calendar .ics file',
            to: 'calendar',
            permissions: ['read modules', 'manage events'],
            roles: [
                'Editorial'
            ]
        },
        {
            icon: 'business',
            title: 'Sponsored Words',
            description: 'Sponsored Words',
            to: 'sponsored-words',
            permissions: ['manage advertising'],
            roles: [
              'crm'
            ]
        }
    ]
}
